import React, { useRef } from "react";
import styled, {css, keyframes} from "styled-components";

import { mediaHelper } from "../utils/media-helper";
import { Colors } from "../constants/Colors";

import 'aos/dist/aos.css'

const fadeIn = keyframes`
  from {
    top: 20px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    top: 0;
    opacity: 1;
  }

  to {
    top: 20px;
    opacity: 0;
  }
`;

const NoStyleMixin = css`
  background: transparent;
  box-shadow: none;
`;

const CardWrapper = styled.div`
  position: relative;
  height: 450px;

  ${mediaHelper.sm`
    height: 500px;    
  `}

  ${mediaHelper.md`
    height: 550px;
  `}

  ${mediaHelper.lg`
    height: 450px;    
  `}


`;

const CardContainer = styled.div`
  background: ${Colors.LIGHTEST_GRAY};
  box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.08);

  position: absolute;
  top: 0;

  &:focus {
    outline:0;
  }

  ${({noStyle}) => noStyle && NoStyleMixin}

  animation: ${({ show, speed }) => show ? css`${fadeIn} ${speed/2}s` : css`${fadeOut} ${speed/2}s`};

  ${mediaHelper.sm`
    ${({ show }) => show && `
      top: 0;
    `}
    animation: ${({ show, speed }) => show ? css`${fadeIn} ${speed/2}s` : css`${fadeOut} ${speed/2}s`};
  `}

  margin-left: 25px;

  ${mediaHelper.sm`
    margin-left: 0;
  `}
`;


const SteadyCard = ({children, noStyle, show, speed, ...props}) => {

    return (
      <CardWrapper>
        <CardContainer
          show={show}
          noStyle={noStyle}
          speed={speed}
          {...props}>
          {children}
        </CardContainer>
      </CardWrapper>
    );
}

export default SteadyCard;
