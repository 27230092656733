import React from "react";
import styled, { css } from "styled-components";

import { MethodologyContentType } from "../../../constants/enums";
import { mediaHelper } from "../../../utils/media-helper";
import { Colors } from "../../../constants/Colors";

const LinesContainer = styled.div`
  position: relative;
`;

const SmallLinesContainer = styled.div``;

const TopDivsWrapper = styled.div`
  position: ${({ big }) => (big ? "absolute" : "relative")};
  top: ${({ big }) => (big ? "-8px" : "")};
  opacity: ${({ show }) => (show ? "1" : "0")};
  transition: opacity 0.25s ease-in-out;
`;

const ArrowLeftBigMixin = css`
  bottom: -5px;

  ${mediaHelper.es`    
    bottom: -7px;
  `};
`;

const ArrowLeft = styled.div`
  border: solid ${Colors.DARK};
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  position: absolute;
  transform: rotate(135deg);
  left: 1px;
  bottom: ${({ bottom }) => (bottom ? "19px" : "1px")};

  ${mediaHelper.es`    
    padding: 5px;
    bottom: ${({ bottom }) => (bottom ? "0" : "1px")};
    border-width: 0 2px 2px 0;
  `};

  ${({ big }) => (big ? ArrowLeftBigMixin : "")}
`;


const InvertedMixin = css`
  position: absolute;
  right: 2px;

  ${({ top }) => (top ? "top: 7px;" : "bottom: 5px;")}
`;

const BottomLine = styled.div`
  width: 10px;
  padding-bottom: ${({ big }) => (big ? "92px" : `130px`)};
  position: relative;
  border-bottom: 1px solid ${Colors.DARK};

  ${mediaHelper.es`    
    width: 20px;
    padding-bottom: 130px;
    border-bottom: 2px solid ${Colors.DARK};
  `};

  ${({ invert }) => (invert ? InvertedMixin : "")}
`;

const CurveBottomRight = styled.div`
  border-right: 1px solid ${Colors.DARK};
  border-bottom: 1px solid ${Colors.DARK};
  border-bottom-right-radius: 50%;
  position: absolute;
  right: ${({ big }) => (big ? "-7px" : "-9px")};
  bottom: ${({ big, bottom }) => (big ? "-1px" : bottom ? "23px" : "5px")};
  width: 9px;
  height: 9px;

  ${mediaHelper.es`    
    width: 14px;
    height: 14px;
    border-right: 2px solid ${Colors.DARK};
    border-bottom: 2px solid ${Colors.DARK};
    right: ${({ big }) => (big ? "-10px" : "-15.6px")};
    bottom: ${({ big }) => (big ? "-2px" : "5px")};
  `};
`;

const BigMixin = css`
  right: -7px;
  top: 18px;

  ${mediaHelper.es`
    top: 0;
    right: -10px;    
  `};
`;

const CurveTopRight = styled.div`
  border-right: 1px solid ${Colors.DARK};
  border-top: 1px solid ${Colors.DARK};
  border-top-right-radius: 50%;
  position: absolute;
  right: -9px;
  top: ${({ bottom }) => (bottom ? "7px" : "21px")};
  width: 9px;
  height: 9px;

  ${mediaHelper.es`    
    right: -15.6px;
    top: 9px;
    width: 14px;
    height: 14px;
    border-right: 2px solid ${Colors.DARK};
    border-top: 2px solid ${Colors.DARK};
  `};

  ${({ big }) => (big ? BigMixin : "")}
`;

const TopLine = styled.div`
  width: ${({ big }) => (big ? "10px" : "")};
  padding-bottom: 106px;
  border-top: 1px solid ${Colors.DARK};
  margin-top: ${({ big }) => (big ? "18px" : "")};

  ${mediaHelper.es`
    margin-top: 0;
    width: ${({ big }) => (big ? "14px" : "")};
    border-top: 2px solid ${Colors.DARK};
  `};

  ${({ invert }) => (invert ? InvertedMixin : "")}
`;

const BigVerticalLineMixin = css`
  height: 187px;
  right: -7px;
  top: 27px;

  ${mediaHelper.es`
    height: 212px;
    top: 14px;
    right: -10px;    
  `};
`;

const VerticalLine = styled.div`
  border-left: 1px solid ${Colors.DARK};
  position: absolute;
  right: -9px;
  top: ${({ bottom }) => (bottom ? "10px" : "24px")};
  height: 70px;

  ${mediaHelper.es`
    right: -15.6px;
    top: 14px;
    height: 75px;
    border-left: 2px solid ${Colors.DARK};
  `};

  ${({ big }) => (big ? BigVerticalLineMixin : "")}
`;

const BackMethodologyLines = ({ active }) => {
  const isMonitoring = active === MethodologyContentType.MONITORING;
  const isValidation = active === MethodologyContentType.VALIDATION;
  const isEvolution = active === MethodologyContentType.EVOLUTION;

  return (
    <LinesContainer>
      <SmallLinesContainer>
        <TopDivsWrapper show={isMonitoring || isValidation || isEvolution}>
          <TopLine>
            <CurveTopRight></CurveTopRight>
            <VerticalLine></VerticalLine>
            <CurveBottomRight></CurveBottomRight>
            <ArrowLeft></ArrowLeft>
          </TopLine>
        </TopDivsWrapper>
        <TopDivsWrapper show={isValidation || isEvolution}>
          <TopLine>
            <CurveTopRight bottom></CurveTopRight>
            <VerticalLine bottom></VerticalLine>
            <CurveBottomRight bottom></CurveBottomRight>
            <ArrowLeft bottom></ArrowLeft>
          </TopLine>
        </TopDivsWrapper>
      </SmallLinesContainer>
      <TopDivsWrapper big show={isMonitoring || isValidation || isEvolution}>
        <TopLine big>
          <CurveTopRight big></CurveTopRight>
          <VerticalLine big></VerticalLine>
        </TopLine>
        <BottomLine big>
          <CurveBottomRight big></CurveBottomRight>
          <ArrowLeft big></ArrowLeft>
        </BottomLine>
      </TopDivsWrapper>
    </LinesContainer>
  );
};

export default BackMethodologyLines;
