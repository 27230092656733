import React, { useState } from "react";
import { Link } from "gatsby";
import styled, { css } from "styled-components";
import scrollTo from "gatsby-plugin-smoothscroll";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Parallax } from "react-scroll-parallax";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'

import {
  RalewayBold,
  RalewaySemiBold,
  RalewayRegular,
} from "../../../components/Text";
import {
  MethodologyContentType,
  GalleryArrowType,
} from "../../../constants/enums";
import { Colors } from "../../../constants/Colors";
import { mediaHelper } from "../../../utils/media-helper";
import { useWindowSize } from "../../../helpers/window-resize";
import { BreakpointValues } from "../../../constants/Breakpoints";

import Card from "../../Card";
import SteadyCard from "../../SteadyCard";
import Button from "../../Button";
import Container from "../../Container";
import Slider from "../../Slider/index.js";
import MethodologyLines from "./MethodologyLines";
import BackMethodologyLines from "./BackMethodologyLines";
import ContainerWrapper from "../../ContainerWrapper";

import { SectionTitle } from "../../layout";
import DiagnosisImage from "../../../assets/img/diagnostico.png";
import DiagnosisImage2x from "../../../assets/img/diagnostico@2x.png";
import BackgroundOffice from "../../../assets/img/Topo-img.png";
import BackgroundOffice2x from "../../../assets/img/Topo-img@2x.png";

import Lines from "../../../assets/img/lines.png";
import Lines2x from "../../../assets/img/lines@2x.png";
import Lines3x from "../../../assets/img/lines@3x.png";
import Lines4x from "../../../assets/img/lines@4x.png";
import Xprints from "../../../assets/img/xprints.png";
import Xprints2x from "../../../assets/img/xprints@2x.png";
import Xprints3x from "../../../assets/img/xprints@3x.png";
import Xprints4x from "../../../assets/img/xprints@4x.png";
import Inovar from "../../../assets/img/inovar.png";
import Inovar2x from "../../../assets/img/inovar@2x.png";
import Inovar3x from "../../../assets/img/inovar@3x.png";
import Inovar4x from "../../../assets/img/inovar@4x.png";
import Transformar from "../../../assets/img/transformar.png";
import Transformar2x from "../../../assets/img/transformar@2x.png";
import Transformar3x from "../../../assets/img/transformar@3x.png";
import Transformar4x from "../../../assets/img/transformar@4x.png";
import Investir from "../../../assets/img/investir.png";
import Investir2x from "../../../assets/img/investir@2x.png";
import Investir3x from "../../../assets/img/investir@3x.png";
import Investir4x from "../../../assets/img/investir@4x.png";
import BigQuote from "../../../assets/img/big-quote.png";
import BigQuote2x from "../../../assets/img/big-quote@2x.png";
import BigQuote3x from "../../../assets/img/big-quote@3x.png";
import BigQuote4x from "../../../assets/img/big-quote@4x.png";
import Scroll from "../../../assets/img/scroll.png";
import Scroll2x from "../../../assets/img/scroll@2x.png";
import Scroll3x from "../../../assets/img/scroll@3x.png";
import Scroll4x from "../../../assets/img/scroll@4x.png";
import Nps from "../../../assets/img/nps.png";
import Nps2x from "../../../assets/img/nps@2x.png";
import Nps3x from "../../../assets/img/nps@3x.png";
import Nps4x from "../../../assets/img/nps@4x.png";
import {
  Diagnosis,
  Monitoring,
  Validation,
  Evolution,
} from "../../../dynamic-content/methodology";

export const TopBanner = styled.div`
  background: url(${BackgroundOffice}) no-repeat center center fixed;
  background-size: cover;
  position: relative;

  @media
  only screen and (-webkit-min-device-pixel-ratio: 2),
  only screen and (   min--moz-device-pixel-ratio: 2),
  only screen and (     -o-min-device-pixel-ratio: 2/1),
  only screen and (        min-device-pixel-ratio: 2),
  only screen and (                min-resolution: 192dpi),
  only screen and (                min-resolution: 2dppx) { 
    background-image: url('${BackgroundOffice2x}');
  }

  ${mediaHelper.lg`
    height: 720px;
  `}

  & > img {
    position: absolute;
    bottom: 200px;
    left: -140px;
    width: 80%;

    ${mediaHelper.es`
      bottom: 100px;
      left: -160px;
      width: 60%;      
    `}

    ${mediaHelper.md`
      width: inherit;
      bottom: 62px;    
    `}
  }
`;

const MoreInfoContainer = styled(Container)`
  background-color: transparent;
  display: flex;
  justify-content: flex-end;

  & > div {
    border-right: 1px solid white;
    height: 100px;
  }
`;

export const BannerContentContainer = styled(Container)`
  padding-bottom: 88px;
  padding-top: 100px;

  ${mediaHelper.md`
    padding-bottom: 160px;
    padding-top: 225px;
    display: flex;
    align-items: center;
  `};

  ${mediaHelper.lg`
    padding-bottom: 0;
    margin-bottom: 140px;
  `}

  div {
    &:first-child {
      flex: 1;
      z-index: 100;
      position: relative;
    }

    h1 {
      ${RalewayBold}
      letter-spacing: 0.03em;
      color: white;
      text-transform: initial;
      opacity: 1;
      font-size: 27px;
      line-height: 42px;

      ${mediaHelper.md`    
        font-size: 40px;
        line-height: 64px;
      `};
    }

    h2 {
      opacity: 0.5;
      text-transform: uppercase;
      color: ${Colors.YELLOW};
      ${RalewayBold}
      font-size: 24px;
      line-height: 50px;

      &:nth-child(2) {
        display: flex;
        align-items: center;
        margin-top: 26px;

        & > span {
          font-size: 64px;
          margin: 0 9px;
          ${RalewayRegular}
        }
      }

      ${mediaHelper.md`    
        font-size: 28px;
        line-height: 64px;
      `};
    }

    & button {
      ${RalewaySemiBold}
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.1em;
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      padding-bottom: 10px;
      border-bottom: 1px solid white;
    }
  }
`;

const DesktopXprintsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  && > button {
    ${RalewayRegular}
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
    padding-top: 13px;
    transition: 0.5s opacity ease;
    position: absolute;
    bottom: -40px;
    width: 110%;
    padding-bottom: 7px;
  }
  &:hover {
    & > button {
      visibility: visible;
      opacity: 1;

      &:hover {
        background-color: transparent;
      }
    }
  }
`;

const ResponsiveXprintsContainer = styled.div`
  display: flex;
  margin-bottom: 26px;
  margin-top: 64px;
  flex-direction: row;

  ${mediaHelper.md`    
    flex-direction: column;  
  `};

  & > div {
    &:first-child {
      flex: 1;

      ${mediaHelper.md`    
        margin-bottom: 16px;  
      `};
    }

    &:nth-child(2) {
      height: 73px;
      width: 73px;

      img {
        width: 100%;
      }
    }

    h2 {
      ${RalewaySemiBold}
      text-transform: uppercase;
      font-size: 12px;
      line-height: 19px;
      letter-spacing: -0.02em;
      color: white;
      margin-bottom: 8px;
      margin-top: 0;
    }
  }
`;

const ContentWrapper = styled.div`
  scroll-behavior: smooth;
`;
const MoreInfoScroll = styled.div`
  padding-right: 19px;

  h2 {
    ${RalewaySemiBold}
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 12px;
    line-height: 19px;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: white;
  }
`;

const VerticalLine = styled.div`
  border-left: 1px solid ${Colors.DARK};
  z-index: 100;
`;

const ValuesCardsContainer = styled(Container)`
  padding: 0;
  margin-top: -88px;
  margin-left: 0;
  margin-right: 0;
  max-width: inherit;

  ${mediaHelper.lg`
    margin-left: auto;
    margin-right: auto; 
    max-width: 720px;
  `};

  ${mediaHelper.lg`
    display: flex;    
    margin-top: 0;
    max-width: 960px;    
  `};

  ${mediaHelper.xl`    
    max-width: 1140px;    
  `};

  ${mediaHelper.xxl`    
    max-width: 1240px;    
  `};

  & > div {
    ${mediaHelper.lg`    
      display: flex;

      &:first-child {
        margin-right: 140px;
        flex: 1;
      }
    `};
  }
`;

export const ValuesCard = styled(Card)`
  padding: 23px 32px;
  margin: 12px 12px 45px 12px;
  min-height: 286.375px;

  ${mediaHelper.lg`    
    max-width: 310px;
    margin: -88px 12px 0 12px;
  `};

  h2 {
    ${RalewayBold}
    font-size: 14px;
    line-height: 159.4%;
    color: ${Colors.DARK};
    text-transform: uppercase;
  }

  p {
    ${RalewayRegular}
    font-size: 16px;
    line-height: 24px;
    color: ${Colors.GRAY};
  }
`;

export const ImageContainer = styled.div`
  height: 103px;
  display: flex;
  align-items: center;
`;

const QuoteContainer = styled(Container)`
  display: flex;

  & > div: first-child {
    position: relative;
    margin-top: 40px;
    ${mediaHelper.md`            
      margin-top: 116px;
      margin-right: 120px;
    `};

    img {
      position: absolute;
      top: -40px;
    }

    h2 {
      color: ${Colors.DARK};
      font-size: 24px;
      line-height: 39px;

      ${mediaHelper.md`                    
        font-size: 32px;
        line-height: 48px;
      `};
    }
  }
`;

const ManifestContainer = styled(Container)`
  display: flex;
  padding-left: 0;
  padding-bottom: 54px;

  ${mediaHelper.es`
    padding-bottom: 80px;
  `}

  ${mediaHelper.md`                    
    padding-bottom: 0;
  `};

  & > div:first-child {
    padding-left: 0;

    ${mediaHelper.md`                    
      padding-top: 80px;
      margin-bottom: 94px;
      font-size: 32px;
      line-height: 48px;
      display: flex;
      flex: 1;
      justify-content: flex-end;
    `};

    ${mediaHelper.lg`
      margin-bottom: 180px;
    `}

    & > div:first-child {
        position: relative;        
        background-image: url('${DiagnosisImage}');
        background-repeat: no-repeat;          
        height: 357px;
        background-size: cover;
        background-position: 157% 33%;

        @media
        only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (   min--moz-device-pixel-ratio: 2),
        only screen and (     -o-min-device-pixel-ratio: 2/1),
        only screen and (        min-device-pixel-ratio: 2),
        only screen and (                min-resolution: 192dpi),
        only screen and (                min-resolution: 2dppx) { 
          background-image: url('${DiagnosisImage2x}');
        }


        ${mediaHelper.md`
          width: 40%;
          background-position: 40% 33%;
          margin-right: 120px;
          background-position: 50% 50%;
          background-size: cover;
          height: 527px;
          position: absolute;
          left: 0;          
          z-index: 100;  
        `};

        ${mediaHelper.lg`             
          width: 45%;
          background-position: 50% 33%;
        `}

    }

    & > div:nth-child(2) {
      margin-top: 84px;

      ${mediaHelper.md`                    
        width: 40%;
        margin-right: 67px;        
      `};

      h2 {
        margin-left: 25px;

        ${mediaHelper.md`                    
          margin-left: 31px;            
        `};
      }
        

      div {
        position: relative;

        &:first-child {
          margin-bottom: 46px;
        }

        div {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 167px;
          background: linear-gradient(0deg,rgba(248,248,248,1) 0%,rgba(248,248,248,1) 40%,rgba(0,0,0,0) 100%);

          ${mediaHelper.md`                    
            height: 60px;
            background: linear-gradient(0deg,rgba(248,248,248,1) 0%,rgba(0,0,0,0) 100%);
          `};

        }

       
        p {
          ${RalewayRegular}
          color: ${Colors.GRAY};
          font-size: 16px;
          line-height: 159.37%;
          margin-left: 25px;

          ${mediaHelper.md`                    
            margin-left: 70px;            
          `};
        }
      }

      a {
        ${RalewayRegular}
        color: ${Colors.DARK};
        font-size: 14px;
        line-height: 159.37%;
        text-transform: uppercase;
        padding-bottom: 7px;
        margin-left: 25px;
        text-decoration: none;
        letter-spacing: 0.1em;
        border-bottom: 1px solid ${Colors.DARK};

        ${mediaHelper.md`                    
          margin-left: 70px;
        `};
      }
    }
  }
`;

const NpsImageContainer = styled(Card)`
  background-color: transparent;
  position: absolute;
  bottom: -14px;
  z-index: 90;
  right: 0;

  & > img {
    box-shadow: 0px 14.0945px 26.4271px rgba(0, 0, 0, 0.12);
    width: 305px;

    ${mediaHelper.lg`
      width: inherit;
    `};
  }
`;

const AboutServiceDesign = styled(Card)`
  position: absolute;
  top: 54%;
  width: 58%;
  z-index: 100;
  right: 0px;

  & > div {
    background-color: ${Colors.DARK};    
    opacity: 0.9;
    padding: 15px;
    box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.12);
  }

  ${mediaHelper.md`                    
    top: 60%;
    right: -30px;
    width: 55%;    

     & > div { 
        padding: 25px 18px;
      }
  `};

  ${mediaHelper.lg`                    
    top: 75%;
    right: -30px;
    width: 55%;         

    & > div {
      padding: 25px 18px;
    }     
  `};

  h2 {
   ${RalewayBold}
   color: ${Colors.YELLOW};
   font-size: 16px;              
   line-height: 142.87%;
   opacity: 1;
     margin-top: 0;
     ${mediaHelper.md`                    
       font-size: 20px;
     `};
   }

   p {
     ${RalewayRegular}
     color: ${Colors.YELLOW};
     font-size: 14px;
     line-height: 23px;     
     opacity: 1;
     margin-bottom: 0;
   ${mediaHelper.md`                    
     font-size: 16px;
     line-height: 160.87%;
   `};
   }        
`;

const MethodologyWrapper = styled(ContainerWrapper)`
  background-color: ${Colors.YELLOW};
`;

const StyledParallax = styled(Parallax)`
  position: relative;
  z-index: 100;
`;

const MethodologySection = styled(Container)`
  display: flex;
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 40px;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
  max-width: inherit;

  ${mediaHelper.sm`
    margin-left: auto;
    margin-right: auto; 
    padding: 0;    
    max-width: 540px;    
  `}

  ${mediaHelper.md`
    max-width: 720px;
    padding-bottom: 0;
  `};

  ${mediaHelper.lg`
    display: flex;    
    margin-top: 0;
    max-width: 960px;  
    flex-direction: row;
  `};

  ${mediaHelper.xl`    
    max-width: 1140px;    
  `};

  ${mediaHelper.xxl`    
    max-width: 1240px;    
  `};

  & > div:first-child {
    padding-top: 55px;
    width: 100%;

    & > h2 {
      margin: 0 25px;

      ${mediaHelper.md`    
        margin: 0;
        padding-left: 0;
        padding-right: 0;  
      `};
    }
  }

  & > ${VerticalLine} {
    border-color: white;
  }
`;

const SlidersContainer = styled.div``;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 25px;
  margin-right: 40px;

  ${mediaHelper.md`    
    padding-left: 0;
    padding-right: 0;  
    margin-left: 0;
  `};

  & > div:first-child {
    flex: 1;
  }

  & > div:nth-child(3) {
      display: flex;
      flex-direction: column;

      button {
        margin: 12px 0;

        ${mediaHelper.es`    
          margin: 24px 0;
        `};
        ${mediaHelper.sm`    
          margin: 12px 0;
        `};
      }
    }
  } 
`;

const TabButton = styled(Button)`
  box-sizing: border-box;
  border-radius: 8.18315px;
  text-align: left;
  transition: 0.5s ease;
  cursor: pointer;
  padding: 15px 12px;
  min-width: 145px;
  position:relative;

  &:before {
    content:"";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1px solid ${Colors.GRAY};
    border-radius: 8.18315px;
  }

  
  &:active {
    background: transparent;

    &:before {
      content:"";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 2px solid ${Colors.DARK};
      border-radius: 8.18315px;
    }

    & h2 {      
      ${RalewaySemiBold}    
      color: ${Colors.DARKER};       
    }
    & p {
      color: ${Colors.DARKER};    
    }
 
  }

  h2 {
    ${({ active }) => (active ? RalewayBold : RalewayRegular)}
    color: ${({ active }) => (active ? "white" : Colors.GRAY)};
    text-transform: uppercase;      
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 12px;
    line-height: 14px;
    ${mediaHelper.sm`                          
      font-size: 18px;
      line-height: 21px;
    `};
  }

  p {
    ${RalewayRegular};
    color: ${({ active }) => (active ? "white" : Colors.GRAY)};      
    font-size: 11px;
    line-height: 13px;
    margin-top: 0;
    margin-bottom: 0;
    ${mediaHelper.sm`                          
      font-size: 14px;
      line-height: 16px;
    `};
  }


  ${mediaHelper.md`                    
    padding: 22px;
    min-width: 200px ;      
    &:hover {
      background: rgba(92, 33, 11, 0.08);

      &:before {
        content:"";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 2px solid ${Colors.DARK};
        border-radius: 8.18315px;
      }

      & h2 {      
        ${RalewaySemiBold}    
        color: ${Colors.DARKER};       
      }
      & p {
        color: ${Colors.DARKER};    
      }
    }
  `};


  ${mediaHelper.lg`
    min-width: 330px;
  `}    

  ${({ active }) => active && TabActiveMixin};
  ${({ past }) => past && TabPastMixin};
`;

const TabActiveMixin = css`
  background: ${Colors.DARK};
  box-shadow: 0px 4.09157px 6.13736px rgba(0, 0, 0, 0.08);

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid ${Colors.DARK};
    border-radius: 8.18315px;
  }
`;

const TabPastMixin = css`
  background: transparent;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 2px solid ${Colors.DARK};
    border-radius: 8.18315px;
  }

  && > h2 {
    ${RalewaySemiBold};
    color: ${Colors.DARKER};
  }

  && > p {
    color: ${Colors.DARKER};
  }
`;

const ResponsiveContactButton = styled(Button)`
  margin: 0 25px;
  background: white;
  padding: 13px 25px;

  ${mediaHelper.sm`
    margin-bottom: 12px;
  `}

  &:hover {
    background: ${Colors.LIGHT_GRAY};
  }
`;

const MethodologyContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
 

  & > div:first-child {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    & > h2 {
      margin-bottom: 11px;
    }

    & > div {
      display: flex;
      button {
        min-width: 200px;
        padding: 15px;
        background-color: white;
        margin-right: -25px;
        margin-left: 25px;
        max-height: 56px;

        &:hover {
          background: ${Colors.LIGHT_GRAY};  
        }

        a {
          text-decoration: none;
          color: ${Colors.DARK};
        }
      }
    }

    p {
      ${RalewayRegular}
      color: ${Colors.DARK};
      font-size: 14px;
      line-height: 159.37%;
      margin-top: 0;
    }
  }
`;

const MethodologyCardsWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 58px;
  margin-left: 0;
  margin-right: 0;

  p {
    ${RalewayRegular}
  }
`;

const MethodologyDescription = styled.div`
  margin: 0 25px;

  ${mediaHelper.md`    
    margin-right: 25px;
    padding-left: 0;
    padding-right: 0;  
    margin-left: 0 !important;
  `};
`;

const StyleValuesCard = styled.div`
  background-color: transparent;
  margin-right: 40px;
  box-shadow: none ${mediaHelper.md`    
    padding: 0;  
  `};

  h2 {
    ${RalewayBold}
    font-size: 16px;
    line-height: 26px;
    margin: 0;
    color: ${Colors.DARK};
    letter-spacing: 0.075em;
    text-transform: uppercase;
  }

  p {
    color: ${Colors.DARK};
    font-size: 16px;
    line-height: 25px;
  }

  h3 {
    ${RalewaySemiBold}
    color: ${Colors.DARK};
    font-size: 18px;
    line-height: 150%;
    margin: 0;
  }
`;

const Home = (props) => {
  const currentWindowSize = useWindowSize();
  const isDesktop = currentWindowSize.width >= BreakpointValues.lg;
  const [tabActive, setTabActive] = useState(1);

  const checkMethodologyContent = () => {
    switch (tabActive) {
      case MethodologyContentType.DIAGNOSIS:
        return [Diagnosis];
      case MethodologyContentType.MONITORING:
        return [Monitoring];
      case MethodologyContentType.VALIDATION:
        return [Validation];
      case MethodologyContentType.EVOLUTION:
        return [Evolution];
      default:
        return [];
        break;
    }
  };

  const getPastTab = () => {
    switch (tabActive) {
      case MethodologyContentType.DIAGNOSIS:
        return {
          diagnosis: false,
          monitoring: false,
          validation: false,
          evolution: false,
        };
      case MethodologyContentType.MONITORING:
        return {
          diagnosis: true,
          monitoring: false,
          validation: false,
          evolution: false,
        };
      case MethodologyContentType.VALIDATION:
        return {
          diagnosis: true,
          monitoring: true,
          validation: false,
          evolution: false,
        };
      case MethodologyContentType.EVOLUTION:
        return {
          diagnosis: true,
          monitoring: true,
          validation: true,
          evolution: false,
        };
      default:
        return {
          diagnosis: false,
          monitoring: false,
          validation: false,
          evolution: false,
        };
        break;
    }
  };
  return (
    <>
      <TopBanner id="page-top">
        <img
          alt="lines"
          srcSet={`
            ${Lines4x} 4x,
            ${Lines3x} 3x,
            ${Lines2x} 2x,
            ${Lines} 1x`}
          src={Lines}
        />
        <BannerContentContainer transparent>
          <div>
            <h1>
              Velocidade e precisão em {isDesktop && <br />} produtos e serviços
              digitais
            </h1>
            <h2>
              construção <span>+</span> evolução
            </h2>
          </div>
          {isDesktop ? (
            <DesktopXprintsContainer>
              <img
                alt="xprints"
                srcSet={`
                  ${Xprints4x} 4x,
                  ${Xprints3x} 3x,
                  ${Xprints2x} 2x,
                  ${Xprints} 1x`}
                src={Xprints}
              />
              <Button transparent onClick={() => scrollTo("#methodology")}>
                Ver metodologia completa
              </Button>
            </DesktopXprintsContainer>
          ) : (
            <ResponsiveXprintsContainer>
              <div>
                <h2>Mais informações</h2>
                <img
                  alt="scroll"
                  srcSet={`
                    ${Scroll4x} 4x,
                    ${Scroll3x} 3x,
                    ${Scroll2x} 2x,
                    ${Scroll} 1x`}
                  src={Scroll}
                />
              </div>
              <div>
                <img
                  alt="xprints"
                  srcSet={`
                    ${Xprints4x} 4x,
                    ${Xprints3x} 3x,
                    ${Xprints2x} 2x,
                    ${Xprints} 1x`}
                  src={Xprints}
                />
              </div>
            </ResponsiveXprintsContainer>
          )}
        </BannerContentContainer>
        {isDesktop ? (
          <MoreInfoContainer>
            <MoreInfoScroll>
              <h2>Mais informações</h2>
              <div>
                <img
                  alt="scroll"
                  srcSet={`
                    ${Scroll4x} 4x,
                    ${Scroll3x} 3x,
                    ${Scroll2x} 2x,
                    ${Scroll} 1x`}
                  src={Scroll}
                />
              </div>
            </MoreInfoScroll>
          </MoreInfoContainer>
        ) : null}
      </TopBanner>
      <ContentWrapper>
        <ValuesCardsContainer id="values">
          {isDesktop ? (
            <div>
              <ValuesCard duration={400}>
                <ImageContainer>
                  <img
                    alt="inovar"
                    srcSet={`
                    ${Inovar4x} 4x,
                    ${Inovar3x} 3x,
                    ${Inovar2x} 2x,
                    ${Inovar} 1x`}
                    src={Inovar}
                  />
                </ImageContainer>
                <h2>Capacidade de Inovar</h2>
                <p>
                  Inverter a lógica tradicional das empresas trabalhando de trás
                  para frente, através de processos contínuos focados na
                  experiência dos seus clientes.
                </p>
              </ValuesCard>
              <ValuesCard duration={800}>
                <ImageContainer>
                  <img
                    alt="transformar"
                    srcSet={`
                    ${Transformar4x} 4x,
                    ${Transformar3x} 3x,
                    ${Transformar2x} 2x,
                    ${Transformar} 1x`}
                    src={Transformar}
                  />
                </ImageContainer>
                <h2>Capacidade de Transformar</h2>
                <p>
                  Identificar novos produtos e serviços aproveitando a relação
                  já existente com os seus clientes como diferencial competitivo
                  para transformar o seu negócio.
                </p>
              </ValuesCard>
              <ValuesCard duration={1200}>
                <ImageContainer>
                  <img
                    alt="investir"
                    srcSet={`
                    ${Investir4x} 4x,
                    ${Investir3x} 3x,
                    ${Investir2x} 2x,
                    ${Investir} 1x`}
                    src={Investir}
                  />
                </ImageContainer>
                <h2>Precisão para Investir</h2>
                <p>
                  Transformar dados gerados pelos clientes em informações
                  estruturadas capazes de ajudar na priorização das iniciativas com
                  maior impacto nos resultados.
                </p>
              </ValuesCard>
            </div>
          ) : (
            <Slider
              className="overlay"
              itemsLength={3}
              mobileCardNum={1.2}
              tabletCardNum={1.2}
              lapTopCardNum={1.2}
              cardNum={3}
              arrowPos="-37px"
              navColor={GalleryArrowType.GRAY}
            >
              <Card noStyle duration={600}>
                <ValuesCard>
                  <ImageContainer>
                    <img
                      alt="inovar"
                      srcSet={`
                    ${Inovar4x} 4x,
                    ${Inovar3x} 3x,
                    ${Inovar2x} 2x,
                    ${Inovar} 1x`}
                      src={Inovar}
                    />
                  </ImageContainer>
                  <h2>Capacidade de Inovar</h2>
                  <p>
                    Inverter a lógica tradicional das empresas trabalhando de
                    trás para frente, através de processos contínuos focados na
                    experiência dos seus clientes.
                  </p>
                </ValuesCard>
              </Card>
              <Card noStyle duration={600}>
                <ValuesCard>
                  <ImageContainer>
                    <img
                      alt="transformar"
                      srcSet={`
                    ${Transformar4x} 4x,
                    ${Transformar3x} 3x,
                    ${Transformar2x} 2x,
                    ${Transformar} 1x`}
                      src={Transformar}
                    />
                  </ImageContainer>
                  <h2>Capacidade de Transformar</h2>
                  <p>
                    Identificar novos produtos e serviços aproveitando a relação
                    já existente com os seus clientes como diferencial
                    competitivo para transformar o seu negócio.
                  </p>
                </ValuesCard>
              </Card>
              <Card noStyle duration={600}>
                <ValuesCard>
                  <ImageContainer>
                    <img
                      alt="investir"
                      srcSet={`
                    ${Investir4x} 4x,
                    ${Investir3x} 3x,
                    ${Investir2x} 2x,
                    ${Investir} 1x`}
                      src={Investir}
                    />
                  </ImageContainer>
                  <h2>Precisão para Investir</h2>
                  <p>
                    Transformar dados gerados pelos clientes em informações
                    estruturadas capazes ajudar na priorização das iniciativas
                    com maior impacto nos resultados.
                  </p>
                </ValuesCard>
              </Card>
            </Slider>
          )}
          {isDesktop ? <VerticalLine></VerticalLine> : null}
        </ValuesCardsContainer>

        <QuoteContainer id="quote">
          <div>
            <img
              alt="big-quote"
              srcSet={`
              ${BigQuote4x} 4x,
              ${BigQuote3x} 3x,
              ${BigQuote2x} 2x,
              ${BigQuote} 1x`}
              src={BigQuote}
            />
            <SectionTitle>
              Engajamento das equipes e evolução contínua do conhecimento, etapa
              por etapa, são os fatores determinantes na transformação das
              organizações
            </SectionTitle>
          </div>
          {isDesktop ? <VerticalLine></VerticalLine> : null}
        </QuoteContainer>
        <ContainerWrapper>
          <ManifestContainer id="manifest">
            <div>
              <Card noStyle duration={600}>
                <NpsImageContainer noStyle duration={1000}>
                  <img
                    alt="nps"
                    srcSet={`
                    ${Nps4x} 4x,
                    ${Nps3x} 3x,
                    ${Nps2x} 2x,
                    ${Nps} 1x`}
                    src={Nps}
                  />
                </NpsImageContainer>
                <AboutServiceDesign noStyle duration={1800}>
                  <div>
                    <h2>Design de Serviço</h2>
                    <p>
                      Usamos os conhecimentos sobre design para projetar a forma
                      como as pessoas vão interagir com o serviço e como ele
                      será entregue para os clientes.
                    </p>
                  </div>
                </AboutServiceDesign>
              </Card>
              <div>
                <SectionTitle>
                  Ajudamos empresas
                  {isDesktop && <br />}a dar um salto digital
                </SectionTitle>
                <div>
                  <p>
                    Empresas da chamada "nova economia" construíram, como o grande diferencial competitivo, processos embasados em dados provindos diretamente dos clientes.
                  </p>
                  <p>
                    Esses processos, com foco na real experiência dos consumidores e não na percepção dos executivos, geraram um outro dinamismo no mercado, proporcionando um ritmo acelerado na evolução dos produtos e serviços.
                  </p>
                  <div></div>
                </div>
                <Link to="/manifest/">Acesse nosso manifesto</Link>
              </div>
            </div>
            {isDesktop ? <VerticalLine></VerticalLine> : null}
          </ManifestContainer>
        </ContainerWrapper>
        <StyledParallax y={["0px", "-120px"]}>
          <MethodologyWrapper>
            <MethodologySection id="methodology">
              <div>
                <SectionTitle>Metodologia</SectionTitle>
                <TabsWrapper>
                  <div>
                    <TabButton
                      past={getPastTab().diagnosis}
                      active={tabActive === MethodologyContentType.DIAGNOSIS}
                      onClick={(e) => {
                          e.preventDefault()
                          trackCustomEvent({        
                            category: "Marque-uma-conversa",          
                            action: "btn-click",          
                            label: "Marque uma conversa - DIAGNÓSTICO",          
                          })
                          setTabActive(MethodologyContentType.DIAGNOSIS)
                        }                        
                      }
                    >
                      <h2>Diagnóstico</h2>
                      <p>Fotografia atual</p>
                    </TabButton>
                  </div>
                  <MethodologyLines></MethodologyLines>
                  <div>
                    <TabButton
                      past={getPastTab().monitoring}
                      active={tabActive === MethodologyContentType.MONITORING}
                      onClick={(e) => {
                          e.preventDefault()
                          trackCustomEvent({        
                            category: "Marque-uma-conversa",          
                            action: "btn-click",          
                            label: "Marque uma conversa - Monitoramento",          
                          })
                          setTabActive(MethodologyContentType.MONITORING)
                        }
                      }
                    >
                      <h2>Monitoramento</h2>
                      <p>Conexão com o cliente</p>
                    </TabButton>
                    <TabButton
                      past={getPastTab().validation}
                      active={tabActive === MethodologyContentType.VALIDATION}
                      onClick={(e) => {
                          e.preventDefault()
                          trackCustomEvent({        
                            category: "Marque-uma-conversa",          
                            action: "btn-click",          
                            label: "Marque uma conversa - Validação",          
                          })
                          setTabActive(MethodologyContentType.VALIDATION)
                        }
                      }
                    >
                      <h2>Validação</h2>
                      <p>Oportunidades</p>
                    </TabButton>
                    <TabButton
                      past={getPastTab().evolution}
                      active={tabActive === MethodologyContentType.EVOLUTION}
                      onClick={(e) => {
                          e.preventDefault()
                          trackCustomEvent({        
                            category: "Marque-uma-conversa",          
                            action: "btn-click",          
                            label: "Marque uma conversa - EVOLUÇÃO",          
                          })
                          setTabActive(MethodologyContentType.EVOLUTION)
                        }
                      }
                    >
                      <h2>Evolução</h2>
                      <p>Rotina do produto</p>
                    </TabButton>
                  </div>
                  <BackMethodologyLines
                    active={tabActive}
                  ></BackMethodologyLines>
                </TabsWrapper>
                <SlidersContainer>
                  {checkMethodologyContent().map((el, index) => (
                    <MethodologyContentInfo key={`${el.title}-${index}`}>
                      <MethodologyDescription>
                        <SectionTitle>{el.title}</SectionTitle>
                        <div>
                          <p>{el.description}</p>
                          {isDesktop ? (
                            <Button
                              transparent
                              onClick={e => {
                                  e.preventDefault()
                                  trackCustomEvent({        
                                    category: "Marque-uma-conversa",          
                                    action: "btn-click",          
                                    label: "Marque uma conversa - Metodologia",          
                                  })
                                  scrollTo("#contact")
                                }
                              }
                            >                            
                              Marque uma conversa
                            </Button>
                          ) : null}
                        </div>
                      </MethodologyDescription>
                      <MethodologyCardsWrapper>
                        <Slider
                          mobileCardNum={1.2}
                          tabletCardNum={2}
                          lapTopCardNum={3}
                          itemsLength={el.cards.length}
                          cardNum={el.cardNum}
                          arrowPos="-4px"
                          navColor={GalleryArrowType.WHITE}
                        >
                          {el.cards.map((card, index) => (
                            <SteadyCard
                              key={`${card.title}-${index}`}
                              show={true}
                              noStyle
                              noAmimation
                              speed={index + 1}
                            >
                              <StyleValuesCard>
                                <ImageContainer>
                                  <img
                                    alt={card.title}
                                    srcSet={`
                                    ${card.img4x} 4x,
                                    ${card.img3x} 3x,
                                    ${card.img2x} 2x,
                                    ${card.img} 1x`}
                                    src={card.img}
                                  />
                                </ImageContainer>
                                <SectionTitle>{card.title}</SectionTitle>
                                <p>{card.description}</p>
                                {card.footer ?                                   
                                  card.footer.map((el, index) => (
                                    <h3 key={index}>{el}</h3>                                  
                                  )                                  
                                ) : null}
                              </StyleValuesCard>
                            </SteadyCard>
                          ))}
                        </Slider>
                      </MethodologyCardsWrapper>
                    </MethodologyContentInfo>
                  ))}
                </SlidersContainer>
              </div>
              {isDesktop ? (
                <VerticalLine></VerticalLine>
              ) : (
                <ResponsiveContactButton
                  transparent
                  onClick={e => {
                      e.preventDefault()
                      trackCustomEvent({        
                        category: "Marque-uma-conversa",          
                        action: "btn-click",          
                        label: "Marque uma conversa - Metodologia",          
                      })
                      scrollTo("#contact")
                    }
                  }
                >
                  Marque uma conversa
                </ResponsiveContactButton>
              )}
            </MethodologySection>
          </MethodologyWrapper>
        </StyledParallax>
      </ContentWrapper>
    </>
  );
};

export default Home;
