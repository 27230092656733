import React from "react";
import styled, { css } from 'styled-components';

import { Colors } from "../../../constants/Colors";
import {mediaHelper} from "../../../utils/media-helper"

const LinesContainer = styled.div`
  width: 100%;
`;

const TopDivsWrapper = styled.div`
  position: relative;
  display: flex;
`;

const TopArrowMixin = css`
  top: 17px;
  ${mediaHelper.es`    
    top: -6px;    
  `};  
`;

const BottomArrowMixin = css`
  bottom: 17px;
  ${mediaHelper.es`    
    bottom: -6px;    
  `};  
`;

const MiddleMixin = css`
  top: -5px;
`;

const ArrowRight = styled.div`
  border: solid ${Colors.DARK};
  border-width: 0 1px 1px 0;
  display: inline-block;
  position: absolute;
  transform: rotate(-45deg);
  padding: 4px;
  right: -1px;

  ${mediaHelper.es`
    right: 1px;
    padding: 5px;
    border-width: 0 2px 2px 0;
  `};  

  ${({invert}) => invert ? BottomArrowMixin : TopArrowMixin}
  ${({middle}) => middle ? MiddleMixin : ''}
`;

const LongLine = styled.div`
  width: calc(100% - 2px);
  margin-top: 12px;
  margin-bottom: 12px;
  position: relative;
  border-bottom: 1px solid ${Colors.DARK};

  ${mediaHelper.es`    
    border-bottom: 2px solid ${Colors.DARK};
  `};
`;

const InvertedMixin = css`
  position: absolute;
  right: 2px;
  width: 0;



  ${({top}) => top ? 'top: 7px;' : 'bottom: 5px;'}
`;

const BottomLine = styled.div`
  position: relative;
  padding-bottom: 106px;
  border-bottom: 1px solid ${Colors.DARK};
  width: calc(100% - 15px);

  ${mediaHelper.es`    
    width: calc(50% - 21px);
    border-bottom: 2px solid ${Colors.DARK};
  `};

  ${({invert}) => invert ? InvertedMixin : ''}

`;

const TopLine = styled.div`
  position: relative;
  padding-bottom: 106px;
  border-top: 1px solid ${Colors.DARK};
  width: calc(100% - 15px);

  ${mediaHelper.es`        
    width: calc(50% - 21px);
    border-top: 2px solid ${Colors.DARK};
  `};

  ${({invert}) => invert ? InvertedMixin : ''}
`;


const CurveBottomRight = styled.div`
  border-bottom-right-radius: 50%;
  position: absolute;
  right: -7px;
  bottom: -1px;
  width: 8px;
  height: 8px;
  border-right: 1px solid ${Colors.DARK};
  border-bottom: 1px solid ${Colors.DARK};
  ${mediaHelper.es`    
    bottom: -2px;
    width: 20px;
    height: 20px;
    right: -20px;
    border-right: 2px solid ${Colors.DARK};
    border-bottom: 2px solid ${Colors.DARK};
  `};
`;

const CurveTopRight = styled.div`
  border-right: 1px solid ${Colors.DARK};
  border-top: 1px solid ${Colors.DARK};
  border-top-right-radius: 50%;
  position: absolute;
  right: -7px;
  width: 8px;
  height: 8px;
  top: -1px;

  ${mediaHelper.es`    
    top: -2px;
    width: 20px;
    height: 20px;
    right: -20px;
    border-right: 2px solid ${Colors.DARK};
    border-top: 2px solid ${Colors.DARK};
  `};
`;



const CurveTopLeft = styled.div`
  border-left: 1px solid ${Colors.DARK};
  border-top: 1px solid ${Colors.DARK};
  position: absolute;
  border-top-left-radius: 50%;
  left: -7px;  
  width: 9px;
  height: 9px;
  top: 21px;

  ${mediaHelper.es`    
    width: 20px;
    height: 20px;
    left: -22px;  
    top: -2px;
    border-left: 2px solid ${Colors.DARK};
    border-top: 2px solid ${Colors.DARK};
  `};
`;

const CurveBottomLeft = styled.div`
  border-left: 1px solid ${Colors.DARK};
  border-bottom: 1px solid ${Colors.DARK};
  position: absolute;
  border-bottom-left-radius: 50%;
  left: -7px;
  bottom: 21px;
  height: 8px;
  width: 8px;

  ${mediaHelper.es`    
    left: -22px;
    bottom: -2px;
    height: 20px;
    width: 20px;    
    border-left: 2px solid ${Colors.DARK};
    border-bottom: 2px solid ${Colors.DARK};
  `};

`;

const VerticalLine = styled.div`
  border-left: 1px solid ${Colors.DARK};
  position: absolute;
  left: -7px;
  top: ${({invert}) => invert ? '12px' : '27px'};
  height: calc(100% - 40px);

  ${mediaHelper.es`    
    top: ${({invert}) => invert ? '21px' : '27px'};
    left: -22px;
    top: 20px;
    border-left: 2px solid ${Colors.DARK};
  `};
`;


const MethodologyLines = () => {
  return (
    <LinesContainer>
      <div>
        <TopDivsWrapper>
          <BottomLine>
            <CurveBottomRight></CurveBottomRight>
          </BottomLine>
          <TopLine invert top>
            <CurveTopLeft></CurveTopLeft>
            <VerticalLine></VerticalLine>
            <ArrowRight></ArrowRight>
          </TopLine>
        </TopDivsWrapper>
        <LongLine>
            <ArrowRight middle></ArrowRight>
        </LongLine>
      </div>
        <TopDivsWrapper>
          <TopLine>
            <CurveTopRight></CurveTopRight>
          </TopLine>
          <BottomLine invert>
            <CurveBottomLeft></CurveBottomLeft>
            <VerticalLine invert></VerticalLine>
            <ArrowRight invert></ArrowRight>
          </BottomLine>
        </TopDivsWrapper>
     
    </LinesContainer>
  );
};

export default MethodologyLines;
