import DiagnosisFirstIcon from "../assets/img/Diagnostico/icon-1.png";
import DiagnosisFirstIcon2x from "../assets/img/Diagnostico/icon-1@2x.png";
import DiagnosisFirstIcon3x from "../assets/img/Diagnostico/icon-1@3x.png";
import DiagnosisFirstIcon4x from "../assets/img/Diagnostico/icon-1@4x.png";
import DiagnosisSecondIcon from "../assets/img/Diagnostico/icon-2.png";
import DiagnosisSecondIcon2x from "../assets/img/Diagnostico/icon-2@2x.png";
import DiagnosisSecondIcon3x from "../assets/img/Diagnostico/icon-2@3x.png";
import DiagnosisSecondIcon4x from "../assets/img/Diagnostico/icon-2@4x.png";
import DiagnosisThirdIcon from "../assets/img/Diagnostico/icon-3.png";
import DiagnosisThirdIcon2x from "../assets/img/Diagnostico/icon-3@2x.png";
import DiagnosisThirdIcon3x from "../assets/img/Diagnostico/icon-3@3x.png";
import DiagnosisThirdIcon4x from "../assets/img/Diagnostico/icon-3@4x.png";
import DiagnosisFourthIcon from "../assets/img/Diagnostico/icon-4.png";
import DiagnosisFourthIcon2x from "../assets/img/Diagnostico/icon-4@2x.png";
import DiagnosisFourthIcon3x from "../assets/img/Diagnostico/icon-4@3x.png";
import DiagnosisFourthIcon4x from "../assets/img/Diagnostico/icon-4@4x.png";
import DiagnosisFithIcon from "../assets/img/Diagnostico/icon-5.png";
import DiagnosisFithIcon2x from "../assets/img/Diagnostico/icon-5@2x.png";
import DiagnosisFithIcon3x from "../assets/img/Diagnostico/icon-5@3x.png";
import DiagnosisFithIcon4x from "../assets/img/Diagnostico/icon-5@4x.png";

import MonitoringFirstIcon from "../assets/img/Monitoramento/icon-1.png";
import MonitoringFirstIcon2x from "../assets/img/Monitoramento/icon-1@2x.png";
import MonitoringFirstIcon3x from "../assets/img/Monitoramento/icon-1@3x.png";
import MonitoringFirstIcon4x from "../assets/img/Monitoramento/icon-1@4x.png";
import MonitoringSecondIcon from "../assets/img/Monitoramento/icon-2.png";
import MonitoringSecondIcon2x from "../assets/img/Monitoramento/icon-2@2x.png";
import MonitoringSecondIcon3x from "../assets/img/Monitoramento/icon-2@3x.png";
import MonitoringSecondIcon4x from "../assets/img/Monitoramento/icon-2@4x.png";
import MonitoringThirdIcon from "../assets/img/Monitoramento/icon-3.png";
import MonitoringThirdIcon2x from "../assets/img/Monitoramento/icon-3@2x.png";
import MonitoringThirdIcon3x from "../assets/img/Monitoramento/icon-3@3x.png";
import MonitoringThirdIcon4x from "../assets/img/Monitoramento/icon-3@4x.png";
import MonitoringFourthIcon from "../assets/img/Monitoramento/icon-4.png";
import MonitoringFourthIcon2x from "../assets/img/Monitoramento/icon-4@2x.png";
import MonitoringFourthIcon3x from "../assets/img/Monitoramento/icon-4@3x.png";
import MonitoringFourthIcon4x from "../assets/img/Monitoramento/icon-4@4x.png";
import MonitoringFithIcon from "../assets/img/Monitoramento/icon-5.png";
import MonitoringFithIcon2x from "../assets/img/Monitoramento/icon-5@2x.png";
import MonitoringFithIcon3x from "../assets/img/Monitoramento/icon-5@3x.png";
import MonitoringFithIcon4x from "../assets/img/Monitoramento/icon-5@4x.png";

import ValidationFirstIcon from "../assets/img/Validation/icon-1.png";
import ValidationFirstIcon2x from "../assets/img/Validation/icon-1@2x.png";
import ValidationFirstIcon3x from "../assets/img/Validation/icon-1@3x.png";
import ValidationFirstIcon4x from "../assets/img/Validation/icon-1@4x.png";
import ValidationSecondIcon from "../assets/img/Validation/icon-2.png";
import ValidationSecondIcon2x from "../assets/img/Validation/icon-2@2x.png";
import ValidationSecondIcon3x from "../assets/img/Validation/icon-2@3x.png";
import ValidationSecondIcon4x from "../assets/img/Validation/icon-2@4x.png";
import ValidationThirdIcon from "../assets/img/Validation/icon-3.png";
import ValidationThirdIcon2x from "../assets/img/Validation/icon-3@2x.png";
import ValidationThirdIcon3x from "../assets/img/Validation/icon-3@3x.png";
import ValidationThirdIcon4x from "../assets/img/Validation/icon-3@4x.png";
import ValidationFourthIcon from "../assets/img/Validation/icon-4.png";
import ValidationFourthIcon2x from "../assets/img/Validation/icon-4@2x.png";
import ValidationFourthIcon3x from "../assets/img/Validation/icon-4@3x.png";
import ValidationFourthIcon4x from "../assets/img/Validation/icon-4@4x.png";

import EvolutionFirstIcon from "../assets/img/Evolution/icon-1.png";
import EvolutionFirstIcon2x from "../assets/img/Evolution/icon-1@2x.png";
import EvolutionFirstIcon3x from "../assets/img/Evolution/icon-1@3x.png";
import EvolutionFirstIcon4x from "../assets/img/Evolution/icon-1@4x.png";
import EvolutionSecondIcon from "../assets/img/Evolution/icon-2.png";
import EvolutionSecondIcon2x from "../assets/img/Evolution/icon-2@2x.png";
import EvolutionSecondIcon3x from "../assets/img/Evolution/icon-2@3x.png";
import EvolutionSecondIcon4x from "../assets/img/Evolution/icon-2@4x.png";
import EvolutionThirdIcon from "../assets/img/Evolution/icon-3.png";
import EvolutionThirdIcon2x from "../assets/img/Evolution/icon-3@2x.png";
import EvolutionThirdIcon3x from "../assets/img/Evolution/icon-3@3x.png";
import EvolutionThirdIcon4x from "../assets/img/Evolution/icon-3@4x.png";

export const Diagnosis = {
    title: "Diagnóstico",
    description: "Mapear a experiência atual dos clientes, desde o primeiro contato até o encerramento do ciclo de atendimento, destacando os problemas e oportunidades durante essa jornada.",
    cardNum: 4,
    cards: [ 
      {
        img: DiagnosisFirstIcon,
        img2x: DiagnosisFirstIcon2x,
        img3x: DiagnosisFirstIcon3x,
        img4x: DiagnosisFirstIcon4x,
        title: "Alinhamento liderança",
        description: "Queremos entender qual a percepção de cada líder em relação aos problemas a serem resolvidos. \n Queremos definir junto com os stakeholders uma proposta de valor a partir da análise de 3 pontos estratégicos.",
        footer: [
          "Proposta de Valor;",
          "Necessidade Usuários;",
          "Métricas de Sucesso"
        ]
      },
      {
        img: DiagnosisSecondIcon,
        img2x: DiagnosisSecondIcon2x,
        img3x: DiagnosisSecondIcon3x,
        img4x: DiagnosisSecondIcon4x,
        title: "Imersão no produto e nas áreas internas",
        description: "Levantamento e entendimento do processo atual, dificuldades das áreas a as dificuldades do produto. Aproveitamos para validar a visão de problemas relatadas pelos líderes."
      },
      {
        img: DiagnosisThirdIcon,
        img2x: DiagnosisThirdIcon2x,
        img3x: DiagnosisThirdIcon3x,
        img4x: DiagnosisThirdIcon4x,
        title: "Benchmarking",
        description: "Explorar as soluções já existentes no mercado para os problemas levantados para problemas análogos, com intuito de gerar mais amplitude e profundidade no tema."
      },
      {
        img: DiagnosisFourthIcon,
        img2x: DiagnosisFourthIcon2x,
        img3x: DiagnosisFourthIcon3x,
        img4x: DiagnosisFourthIcon4x,
        title: "Pesquisas",
        description: "Validar a percepção de problemas pela visão dos usuários do produto (internos ou externos) inicialmente através de conversas exploratórias qualitativas, e posteriormente, expandindo a coleta com dados quantitativos."
      },
       {
        img: DiagnosisFithIcon,
        img2x: DiagnosisFithIcon2x,
        img3x: DiagnosisFithIcon3x,
        img4x: DiagnosisFithIcon4x,
        title: "Experience map",
        description: "Representar de forma visual a jornada atual do produto ou serviço. Identificamos suas etapas, pontos de contato com o usuário final, problemas e oportunidades. O mapa promove o alinhamento de toda a equipe envolvida no projeto e ilustra o cenário atual para nos concentramos nas soluções."
      }
    ]
}

export const Monitoring = {
    title: "Monitoramento",
    description: "Assim que soubermos um pouco mais do seu produto e do seu usuário, planejaremos a metodologia mais adequada de pesquisa e monitoramento para que as oportunidades continuem sendo mapeadas com o tempo. Vamos começar a transformar a foto, elaborada na fase de diagnóstico em filme.",
    cardNum: 4,
    cards: [
      {
        img: MonitoringFirstIcon,
        img2x: MonitoringFirstIcon2x,
        img3x: MonitoringFirstIcon3x,
        img4x: MonitoringFirstIcon4x,
        title: "Rotina de NPS, CES, CSAT",
        description: "Métricas para medir a experiência do seu consumidor, na prática.",        
      },
      {
        img: MonitoringSecondIcon,
        img2x: MonitoringSecondIcon2x,
        img3x: MonitoringSecondIcon3x,
        img4x: MonitoringSecondIcon4x,
        title: "Redes Sociais e lojas de aplicativos",
        description: "Levantamento e padronização dos comentários realizados pelos clientes."
      },
      {
        img: MonitoringThirdIcon,
        img2x: MonitoringThirdIcon2x,
        img3x: MonitoringThirdIcon3x,
        img4x: MonitoringThirdIcon4x,
        title: "Taxonomia",
        description: "Levantamento e padronização dos comentários realizados pelos clientes."
      },
      {
        img: MonitoringFourthIcon,
        img2x: MonitoringFourthIcon2x,
        img3x: MonitoringFourthIcon3x,
        img4x: MonitoringFourthIcon4x,
        title: "Classificação",
        description: "Classificamos as similaridades encontradas nos problemas e oportunidades das pesquisas para atacarmos temas específicos."
      },
      {
        img: MonitoringFithIcon,
        img2x: MonitoringFithIcon2x,
        img3x: MonitoringFithIcon3x,
        img4x: MonitoringFithIcon4x,
        title: "Atualização mapas",
        description: "Manter a jornada do usuário, interações e experiências atualizadas a cada implementação."
      }
    ]
}

export const Validation = {
    title: "Validação",
    description: "Com a priorização em mãos, podem surgir novas oportunidades que hoje o seu produto não atende. Para validar se vale a pena investir nessas oportunidades, podemos criar testes rápidos e mais econômicos através de uma equipe multidisciplinar (negócios + técnica) apartada da esteira do seu produto. Resumidamente o objetivo é conseguir dar mais autonomia para área de negócios descobrir o que realmente tem valor para o cliente, sem a complexidade de ter todo desenvolvimento de uma nova funcionalidade ou até de um novo produto.",
    cardNum: 4,
    cards: [
      {
        img: ValidationFirstIcon,
        img2x: ValidationFirstIcon2x,
        img3x: ValidationFirstIcon3x,
        img4x: ValidationFirstIcon4x,
        title: "Testes rápidos",
        description: "Nem todo lançamento é um sucesso, por isso, é necessário aumentar ritmo de releases. Temos a cultura de adoção generalizada de testes contínuos.",        
      },
      {
        img: ValidationSecondIcon,
        img2x: ValidationSecondIcon2x,
        img3x: ValidationSecondIcon3x,
        img4x: ValidationSecondIcon4x,
        title: "Prototipação",
        description: "Toda solução precisa de uma representação conceitual, Teste e valide antes do desenvolvimento."
      },
      {
        img: ValidationThirdIcon,
        img2x: ValidationThirdIcon2x,
        img3x: ValidationThirdIcon3x,
        img4x: ValidationThirdIcon4x,
        title: "Coleta de Feedback e métricas",
        description: "o próprio produto e as novas implementação é uma fonte de coleta de dados para entregarmos mais valor para o cliente."
      },
      {
        img: ValidationFourthIcon,
        img2x: ValidationFourthIcon2x,
        img3x: ValidationFourthIcon3x,
        img4x: ValidationFourthIcon4x,
        title: "Prova de conceito",
        description: "Uma implementação resumida e simples, que antecede o protótipo do projeto e, assim busca validar um release transformador."
      }    
    ]
}

export const Evolution = {
    title: "Evolução",
    description: "A priorização nos trará oportunidades de sustentação e evolução do seu produto. Também podemos te ajudar com essas demandas do dia-a-dia, entregando interfaces, testes de usabilidade, testes A/B ou desenvolvimento front-end.",
    cardNum: 3,
    cards: [
      {
        img: EvolutionFirstIcon,
        img2x: EvolutionFirstIcon2x,
        img3x: EvolutionFirstIcon3x,
        img4x: EvolutionFirstIcon4x,
        title: "Planejamento",
        description: "Desenvolvimento de toda solução através de uma representação conceitual, prototipação de baixa e alta fidelidade, capaz de realizar testes e validações antes do desenvolvimento.",        
      },
      {
        img: EvolutionSecondIcon,
        img2x: EvolutionSecondIcon2x,
        img3x: EvolutionSecondIcon3x,
        img4x: EvolutionSecondIcon4x,
        title: "desenvolvimento front-end",
        description: "Em virtude da nossa experiência, geralmente fica a cargo da própria Wefit o desenvolvimento das interfaces. Nossa proposta é garantir que todo conhecimento angariado nas etapas anteriores será aplicado e respeitado durante o desenvolvimento."
      },
      {
        img: EvolutionThirdIcon,
        img2x: EvolutionThirdIcon2x,
        img3x: EvolutionThirdIcon3x,
        img4x: EvolutionThirdIcon4x,
        title: "Testes e QA",
        description: "Conjunto de atividades que tentam garantir que o produto ou serviço oferecido esteja de acordo com a experiência proposta e concebida nas fases anteriores."
      }    
    ]
}